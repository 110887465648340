import * as React from 'react';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import FileUpload from '@adecco/base-app/src/components/molecules/FileUpload/FileUpload';
import { moreThan, lessThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import dynamic from 'next/dynamic';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { useFormContext } from 'react-hook-form';
import { MAX_SIZE } from '@adecco/base-app/src/constants/fileUpload';

const AnimateHeight = dynamic(() => import('react-animate-height'), { ssr: false });

export interface FileUploadBlock {
  optionalHeadline: string;
  errors: {
    file1?: boolean;
    file4?: boolean;
  };
  isOptional: boolean;
}
export interface IFileUploadWrapperStyled {
  open?: boolean;
}

const FileUploadWrapper = styled.div`
  cursor: pointer;
`;

const pseudoelementsBase = css<IFileUploadWrapperStyled>`
  content: '';
  position: absolute;
  background-color: ${(props) => props.theme.colors.faqItemOpenElement};
  height: 2px;
  top: ${(props) => (props.open ? '8px' : '9px')};
  width: ${(props) => (props.open ? '13px' : '18px')};
  transition: transform 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;

  ${lessThan('md')} {
    top: ${(props) => (props.open ? '9px' : '12px')};
  }
`;

const FileUploadWrappedHeadline = styled(Headline)<IFileUploadWrapperStyled>`
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  font-size: ${calcrem(18)};
  line-height: ${calcrem(20)};
  padding-left: 38px;
  margin-bottom: ${(props) => (props.open ? '20px' : '10px')};
  margin-top: 4px;
  position: relative;
  transition: margin-bottom 0.4s ease-in-out;

  ${moreThan('md')} {
    margin-top: 0;
    margin-bottom: ${(props) => (props.open ? '24px' : '18px')};
    padding-left: 56px;
  }

  &::before {
    ${pseudoelementsBase};
    left: ${(props) => (props.open ? '13px' : '14px')};
    transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    ${lessThan('md')} {
      left: ${(props) => (props.open ? '5px' : '4px')};
    }
  }

  &::after {
    ${pseudoelementsBase};
    left: ${(props) => (props.open ? '21px' : '14px')};
    transform: ${(props) => (props.open ? 'rotate(-135deg)' : 'rotate(90deg)')};

    ${lessThan('md')} {
      left: ${(props) => (props.open ? '13px' : '4px')};
    }
  }
`;

export const FileUploadBlock: React.FunctionComponent<FileUploadBlock> = ({
  optionalHeadline,
  errors,
  isOptional,
}) => {
  const { watch, setValue } = useFormContext();
  const { t } = useAppContext();
  const [open, setOpen] = useState(false);
  const [cVfileErrorMessage, setCVfileErrorMessage] = useState<string | null>(null);
  const watchFiles = watch(['file1', 'file4']);

  useEffect(() => {
    const size1 = (watchFiles[0] && watchFiles[0][0]?.size) || 0;

    if (errors.file1) {
      if (size1 > MAX_SIZE) {
        setCVfileErrorMessage(t('error-upload-files-size'));
        return;
      }

      setCVfileErrorMessage(t('error-upload-resume'));
      return;
    }

    setCVfileErrorMessage(null);
  }, [watchFiles]);

  const innerMarkup = (
    <>
      <FormRow>
        <FieldGroup size={1}>
          <FileUpload
            label={`${t('form-field-upload-resume')}`}
            cvMaker={t('form-field-create-cv')}
            name="file1"
            placeholder={t('form-field-upload-placeholder')}
            helpText={t('form-field-cv-upload-help-info-text')}
            multiple={false}
            error={errors.file1}
            required={!isOptional}
            onClear={() => {
              setValue('file1', []);
            }}
          />
          {cVfileErrorMessage && (
            <ErrorMessage text={cVfileErrorMessage} paddingTop elementName="file1" />
          )}
        </FieldGroup>
      </FormRow>
      <FormRow>
        <FieldGroup size={1}>
          <FileUpload
            label={`${t('form-field-upload-documents')}`}
            name="file4"
            multiple
            placeholder={t('form-field-upload-placeholder')}
            helpText={t('form-field-documents-upload-help-info-text')}
            error={errors.file4}
            onClear={() => {
              setValue('file4', []);
            }}
          />
          {errors.file4 && (
            <ErrorMessage text={t('error-upload-files-size')} paddingTop elementName="file4" />
          )}
        </FieldGroup>
      </FormRow>
    </>
  );

  const wrappedMarkup = (
    <>
      <FileUploadWrapper onClick={() => setOpen(!open)} data-cy="dropdown-optional-file-upload">
        <FileUploadWrappedHeadline skin={3} open={open}>
          {optionalHeadline}
        </FileUploadWrappedHeadline>
      </FileUploadWrapper>
      <AnimateHeight
        duration={500}
        easing="ease-in-out"
        height={open ? 'auto' : 0} // see props documentation below
      >
        {innerMarkup}
      </AnimateHeight>
    </>
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isOptional ? wrappedMarkup : innerMarkup}</>;
};
