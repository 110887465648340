import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

const ArrowRightIconRed = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/ArrowRightIconRed')
);
const BuildingIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/BuildingIcon')
);
const CalenderProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/CalenderProServIcon')
);
const CheckmarkIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/CheckmarkIcon')
);
const ClockProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/CalenderProServIcon')
);
const CloseXIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/CloseXIcon')
);
const DocumentIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/DocumentIcon')
);
const DocumentProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/DocumentProServIcon')
);
const EditIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/EditIcon'));
const EmailFilledIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/EmailFilledIcon')
);
const EmailProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/EmailProServIcon')
);
const FlagDeIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagDeIcon')
);
const FlagEnIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagEnIcon')
);
const FlagPlIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagPlIcon')
);
const HeadsetProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/HeadsetProServIcon')
);
const HeartIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/HeartIcon')
);
const HeartLineIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/HeartLineIcon')
);
const Icon404 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon404'));
const Icon410 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon410'));
const Icon500 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon500'));
const InformationIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIcon')
);
const InformationIconFilled = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIconFilled')
);
const LocationProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/LocationProServIcon')
);
const LogoFacebookIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoFacebook')
);
const LogoInstagram = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoInstagram')
);
const LogoKununuIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoKununuIcon')
);
const LogoLinkedInIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoLinkedInIcon')
);
const LogoMapsIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoMapsIcon')
);
const LogoXingIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoXingIcon')
);
const NoCVProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/NoCVProServIcon')
);
const PeopleProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/PeopleProServIcon')
);
const PinProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/PinProServIcon')
);
const PlacementTypeProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/PlacementTypeProServIcon')
);
const PlusIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/PlusIcon'));
const ProServLogo = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/ProServLogo')
);
const SearchIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/SearchIcon')
);
const SuitCaseProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/SuitCaseProServIcon')
);
const ThxBriefCaseProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/SuitCaseProServIcon')
);
const ThxContactProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/PlacementTypeProServIcon')
);
const ThxHeartProServIcon = dynamic(
  () => import('@adecco/proserv-app/src/components/atoms/Icon/Icons/ThxHeartProServIcon')
);
const HelpTextInfoIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIcon')
);

export type IconMapType = {
  'help-text-info': ComponentType<{}>;
  logo: ComponentType<{}>;
  'arrow-right-red': ComponentType<{}>;
  building: ComponentType<{}>;
  calender: ComponentType<{}>;
  checkmark: ComponentType<{}>;
  clock: ComponentType<{}>;
  'close-x': ComponentType<{}>;
  document: ComponentType<{}>;
  'document-tile': ComponentType<{}>;
  edit: ComponentType<{}>;
  email: ComponentType<{}>;
  thx_email_confirmation: ComponentType<{}>;
  'email-filled': ComponentType<{}>;
  'flag-de': ComponentType<{}>;
  'flag-en': ComponentType<{}>;
  'flag-pl': ComponentType<{}>;
  headset: ComponentType<{}>;
  heart: ComponentType<{}>;
  'heart-line': ComponentType<{}>;
  information: ComponentType<{}>;
  'information-filled': ComponentType<{}>;
  location: ComponentType<{}>;
  logo_facebook: ComponentType<{}>;
  logo_instagram: ComponentType<{}>;
  logo_kununu: ComponentType<{}>;
  logo_linkedin: ComponentType<{}>;
  logo_maps: ComponentType<{}>;
  logo_xing: ComponentType<{}>;
  'no-cv': ComponentType<{}>;
  people: ComponentType<{}>;
  pin: ComponentType<{}>;
  'placement-type': ComponentType<{}>;
  plus: ComponentType<{}>;
  thx_profile: ComponentType<{}>;
  proServLogo: ComponentType<{}>;
  search: ComponentType<{}>;
  suitcase: ComponentType<{}>;
  thx_briefcase: ComponentType<{}>;
  thx_contact: ComponentType<{}>;
  thx_heart: ComponentType<{}>;
  '404': ComponentType<{}>;
  '410': ComponentType<{}>;
  '500': ComponentType<{}>;
  'job-location': ComponentType<{}>;
};

export const IconMap: IconMapType = {
  'job-location': LocationProServIcon,
  'help-text-info': HelpTextInfoIcon,
  logo: ProServLogo,
  'arrow-right-red': ArrowRightIconRed,
  building: BuildingIcon,
  calender: CalenderProServIcon,
  checkmark: CheckmarkIcon,
  clock: ClockProServIcon,
  'close-x': CloseXIcon,
  document: DocumentIcon,
  'document-tile': DocumentProServIcon,
  edit: EditIcon,
  email: EmailProServIcon,
  thx_email_confirmation: ThxContactProServIcon,
  'email-filled': EmailFilledIcon,
  'flag-de': FlagDeIcon,
  'flag-en': FlagEnIcon,
  'flag-pl': FlagPlIcon,
  headset: HeadsetProServIcon,
  heart: HeartIcon,
  'heart-line': HeartLineIcon,
  information: InformationIcon,
  'information-filled': InformationIconFilled,
  location: LocationProServIcon,
  logo_facebook: LogoFacebookIcon,
  logo_instagram: LogoInstagram,
  logo_kununu: LogoKununuIcon,
  logo_linkedin: LogoLinkedInIcon,
  logo_maps: LogoMapsIcon,
  logo_xing: LogoXingIcon,
  'no-cv': NoCVProServIcon,
  people: PeopleProServIcon,
  pin: PinProServIcon,
  'placement-type': PlacementTypeProServIcon,
  plus: PlusIcon,
  thx_profile: ThxBriefCaseProServIcon,
  proServLogo: ProServLogo,
  search: SearchIcon,
  suitcase: SuitCaseProServIcon,
  thx_contact: ThxHeartProServIcon,
  thx_briefcase: ThxBriefCaseProServIcon,
  thx_heart: ThxHeartProServIcon,
  '404': Icon404,
  '410': Icon410,
  '500': Icon500,
};

export type IconType = keyof IconMapType;
