import * as React from 'react';
import { useState } from 'react';
import HelpText from '@adecco/base-app/src/components/atoms/HelpText/HelpText';
import CvMaker from '@adecco/base-app/src/components/atoms/CvMaker/CvMaker';
import { RequiredStar } from '@adecco/base-app/src/components/atoms/FormField/FormField';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import styled from 'styled-components';
import { lessThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { ALLOWED_TYPES, MAX_SIZE } from '@adecco/base-app/src/constants/fileUpload';
import { validatedFiles } from '@adecco/base-app/src/utilities/forms/validateFiles';
import {
  ClearButton,
  FakeButton,
  FakeField,
  FakeLabel,
  FieldRow,
  FileLabel,
  HiddenFileInput,
} from './FileField.styles';

export interface IFileField {
  label?: string;
  cvMaker?: string | null;
  name: string;
  required?: boolean;
  redStar?: boolean;
  placeholder?: string;
  helpText?: string;
  error?: boolean;
  small?: boolean;
  multiple?: boolean;
  onChange?: (files: FileList) => void;
  onClear?: () => void;
}

const FakeFieldStyled = styled(FakeField)<{ filled: boolean }>`
  ${lessThan('md')} {
    display: ${(props) => (props.filled ? 'flex' : 'none')};
    padding-inline: 0 50px;
    box-shadow: none;
    border: none;
    padding-block: 0;
  }
`;

const FakeButtonStyled = styled(FakeButton)<{ filled: boolean }>`
  ${lessThan('md')} {
    display: ${(props) => (props.filled ? 'none' : 'inherit')};
  }
`;

const FileField: React.FunctionComponent<IFileField> = ({
  label,
  cvMaker,
  name,
  required,
  redStar,
  placeholder,
  helpText,
  error,
  small,
  multiple,
  onClear,
}) => {
  const [filenames, setFileNames] = useState<Array<string>>([]);
  const { t } = useAppContext();
  const { register, setError, clearErrors } = useFormContext();

  const handleChange = (selectorFiles: FileList | null) => {
    if (selectorFiles === null) return false;
    const fileList = Array.from(selectorFiles);

    let notValid = false;
    let fileListSize = 0;

    setFileNames(
      fileList.map((file) => {
        const checkResumeSize = file.size < MAX_SIZE;
        const checkResumeType = ALLOWED_TYPES.test(file.name);
        fileListSize += file.size || 0;
        if (!checkResumeSize || !checkResumeType || fileListSize > MAX_SIZE) {
          notValid = true;
        }
        return file.name;
      })
    );

    if (notValid) {
      setError(name, {});
      return false;
    }

    clearErrors(name);

    return true;
  };

  const clearFiles = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (onClear) {
      onClear();
    }
    clearErrors(name);

    setFileNames([]);
  };

  return (
    <FieldRow>
      <FakeLabel>
        {label}
        {required && <RequiredStar red={redStar}> *</RequiredStar>}
        {helpText ? <HelpText text={helpText} show={error} /> : null}
        {cvMaker ? <CvMaker text={cvMaker} /> : null}
      </FakeLabel>
      <FileLabel htmlFor={name} small={small}>
        <FakeFieldStyled error={!!error} filled={Boolean(filenames.length)}>
          {filenames.length ? filenames.join(', ') : placeholder}
          {filenames.length ? <ClearButton onClick={clearFiles} /> : null}
        </FakeFieldStyled>
        <FakeButtonStyled filled={Boolean(filenames.length)} small={small}>
          {t('general-search')}
        </FakeButtonStyled>
        <HiddenFileInput
          type="file"
          id={name}
          multiple={multiple}
          {...register(name, {
            required,
            onChange: (e) => handleChange(e.target.files),
            validate: validatedFiles,
          })}
          data-cy={`input-${name}`}
        />
      </FileLabel>
    </FieldRow>
  );
};

export default FileField;
