const fonts = `
  @font-face {
    font-family: Roboto;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/Roboto.woff2') format('woff2');
    src: url('/fonts/Roboto.woff') format('woff');
  }
  @font-face {
    font-family: Roboto Bold;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/Roboto.bold.woff2') format('woff2');
    src: url('/fonts/Roboto.bold.woff') format('woff');
  }
`;

export default fonts;
