import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';

interface IInfoText {
  text: string;
  className?: string;
  isEmphasized?: boolean;
  isCentered?: boolean;
  iconName?: IconType;
}

interface InfoTextStyled {
  isEmphasized?: boolean;
  isCentered?: boolean;
}

const InfoTextStyled = styled.div<InfoTextStyled>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'normal')};

  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.margins.infoText};
  }
`;

const InfoTextText = styled.div<InfoTextStyled>`
  font-family: ${(props) =>
    props.isEmphasized ? props.theme.fonts.HeadlineBold : props.theme.fonts.Copy};
  font-style: ${(props) => props.theme.fontStyles.infoTextText};
  color: ${(props) => (props.isEmphasized ? props.theme.colors.text : props.theme.colors.infoText)};
  font-size: ${(props) => (props.isEmphasized ? `${calcrem(18)}` : props.theme.fontSizes.infoText)};
  line-height: ${(props) => props.theme.lineHeights.infoText};
  padding-left: 10px;
  padding-top: 3px;
  width: ${(props) => (props.isCentered ? 'auto' : '100%')};
  font-weight: ${(props) => props.theme.fontWeights.infoText};
`;

const InfoText: React.FunctionComponent<IInfoText> = ({
  text,
  className,
  isEmphasized,
  isCentered,
  iconName,
}) => {
  const theme = useTheme();

  return (
    <InfoTextStyled className={className} isCentered={isCentered}>
      <Icon
        name={iconName || 'information'}
        height={24}
        width={24}
        fill={
          iconName === 'information-filled' || isEmphasized ? undefined : theme.colors.infoTextIcon
        }
      />
      <InfoTextText isEmphasized={isEmphasized} className={className} isCentered={isCentered}>
        {text}
      </InfoTextText>
    </InfoTextStyled>
  );
};

export default InfoText;
