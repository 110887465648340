import DataProtectionModal from '@adecco/base-app/src/components/molecules/DataProtectionModal/DataProtectionModal';
import { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegisterReturn,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import CheckBoxLabel from '@adecco/base-app/src/components/atoms/CheckBoxLabel/CheckBoxLabel';
import * as React from 'react';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import styled from 'styled-components';
import CheckBox from '@adecco/base-app/src/components/atoms/CheckBox/CheckBox';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export type TFieldName = FieldPath<FieldValues>;

type LegalCheckboxes = {
  register: (
    name: TFieldName,
    options?: RegisterOptions<FieldValues, TFieldName>
  ) => UseFormRegisterReturn;
  ageCheckboxHandler: () => void;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  whatsappAvailable?: boolean;
};

const AppCheckBox = styled(CheckBox)<{ noMarginBottom?: boolean }>`
  ${moreThan('md')} {
    &:last-child {
      margin-bottom: ${(props) => (props.noMarginBottom ? 0 : calcrem(10))};
    }
  }
`;

const FormRowStyled = styled(FormRow)`
  margin-bottom: 16px;
`;

const LegalCheckboxes: React.FunctionComponent<LegalCheckboxes> = ({
  register,
  errors,
  setValue,
  ageCheckboxHandler,
  whatsappAvailable,
}) => {
  const { withAgeConfirm, t, withPrivacyPolicyModal } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FormRowStyled>
        <FieldGroup size={1}>
          <CheckBoxLabel>{t('form-field-legal-label')}</CheckBoxLabel>
          <AppCheckBox
            name="privacyPolicy"
            error={!!errors.privacyPolicy}
            required
            register={register('privacyPolicy', {
              required: true,
              onChange: (e) => {
                if (withPrivacyPolicyModal) {
                  setModalOpen(e.target.checked);
                }
              },
            })}
          >
            {t('form-field-legal-privacy-policy')}
          </AppCheckBox>
          <AppCheckBox
            name="termsOfUse"
            error={!!errors.termsOfUse}
            required
            register={register('termsOfUse', {
              required: true,
              onChange: () => {
                if (withAgeConfirm) {
                  ageCheckboxHandler();
                }
              },
            })}
          >
            {t('form-field-legal-terms-of-use')}
          </AppCheckBox>
          <AppCheckBox
            name="MarketingOptIn"
            register={register('MarketingOptIn', { required: false })}
            noMarginBottom={!whatsappAvailable}
            noPaddingBottom={!whatsappAvailable}
          >
            {t('form-field-legal-confirm-email-updates')}
          </AppCheckBox>
          {whatsappAvailable && (
            <AppCheckBox
              name="whatsappAllowedStatus"
              register={register('whatsappAllowedStatus', { required: false })}
              noMarginBottom
              noPaddingBottom
            >
              {t('form-field-legal-confirm-whatsapp-allowed')}
            </AppCheckBox>
          )}
        </FieldGroup>
      </FormRowStyled>
      <DataProtectionModal
        headerTitle={t('modal-data-protection-title')}
        content={t('modal-data-protection-content')}
        mainButtonTitle={t('modal-data-protection-button-confirm')}
        secondaryButtonTitle={t('modal-data-protection-button-close')}
        closeModal={(accept: boolean) => {
          setValue('privacyPolicy', accept);
          setModalOpen(false);
        }}
        isOpen={modalOpen}
      />
    </>
  );
};

export default LegalCheckboxes;
