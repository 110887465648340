import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalStyle from '@adecco/base-app/src/styles/globals';
import { useApollo } from '@adecco/base-app/src/apollo/useApollo';
import defaultTheme from '@adecco/base-app/src/constants/themes/defaultTheme/defaultTheme';
import PageHead from '@adecco/base-app/src/components/organisms/PageHead/PageHead';
import Footer from '@adecco/base-app/src/components/molecules/Footer/Footer';
import { TranslationType } from '@adecco/base-app/src/gql/schema/generated';
import getConfigurations from '@adecco/base-app/src/utilities/getConfigurations/getConfigurations';
import { AppProvider } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { transformLanguage } from '@adecco/base-app/src/utilities/languages/languages';
import Script from 'next/script';
import { PageType } from '@adecco/base-app/pages/job/apply/apply';
import { getSocialLinks } from '@adecco/base-app/src/utilities/getSocialLinks/getSocialLinks';
import Custom500 from '@adecco/base-app/src/components/templates/500/500';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
    OneTrust: { OnConsentChanged: (p: object) => {} };
    OnetrustActiveGroups: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OptanonWrapper: any;
  }
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const languages = {};
  pageProps?.data?.frontendTranslations?.forEach((frontendTranslation: TranslationType) => {
    // @ts-ignore
    languages[frontendTranslation.language] = transformLanguage(frontendTranslation.translations);
  });
  const apolloClient = useApollo(pageProps);
  const jobData = pageProps.data?.jobad;
  const configurations = getConfigurations(pageProps?.data?.frontendConfigurations);
  const socialLinks = getSocialLinks(configurations);
  const trackingKeyService = configurations.trackingKeyService || 'collect.proserv-dl.de';

  return (
    <>
      {configurations.cookieBanner === 'true' ? (
        <>
          <Script
            id="cookiebanner"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            // @ts-ignore
            data-domain-script={
              configurations.cookieBannerKey || '03c69971-24c2-4c5b-b4cf-152dbc0c1bb7'
            }
            strategy="beforeInteractive"
          />
          <Script
            id="cookiebanner-push"
            type="text/javascript"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                // eslint-disable-next-line quotes
                'function OptanonWrapper() { }',
            }}
          />
        </>
      ) : null}
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://${trackingKeyService}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${configurations.trackingKey}');
          `,
        }}
      />
      <Head>
        <title>{jobData ? jobData.title : 'ProServ Jobs'}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={jobData ? jobData.title : 'ProServ Jobs'} />
        <meta name="description" content={jobData ? jobData.group?.ogDescription : ''} />
        <meta property="og:description" content={jobData ? jobData.group?.ogDescription : ''} />
        {jobData && jobData.group?.ogImage ? (
          <meta property="og:image" content={jobData.group?.ogImage} />
        ) : null}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <GlobalStyle />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={defaultTheme}>
          <AppProvider
            withDoi={configurations.withDoi === 'true'}
            withImprint={configurations.withImprint === 'true'}
            withAgeConfirm={configurations.withAgeConfirm === 'true'}
            languages={languages}
            brand="ProServ"
            privacyConsentVersion={configurations.privacyConsentVersion}
            withPrivacyPolicyModal={configurations.withPrivacyPolicyModal === 'true'}
            socialLinks={socialLinks}
            withTracking={configurations.withTracking === 'true'}
            zipRegExp={configurations.zipRegExp}
            varSource={configurations.varSource}
            logoLink={configurations.logoLink}
            withNoAts={configurations.withNoAts === 'true'}
            withJsonSchema={configurations.withJsonSchema === 'true'}
            mobileCVException={configurations.mobileCVException !== 'false'}
          >
            <ModalProvider>
              <PageHead
                key={pageProps.pageType === PageType ? 'compact' : 'normal'}
                compact={
                  configurations.jobSearch !== 'true' ? true : pageProps.pageType === PageType
                }
              />
              <main>
                <ErrorBoundary FallbackComponent={Custom500}>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </main>
              <Footer />
            </ModalProvider>
          </AppProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
