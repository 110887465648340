import { createContext, useContext, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { isJsonString } from '@adecco/base-app/src/utilities/isJsonString';
import { AppContextType, AppProviderProps } from './appProvider.types';

export const AppContext = createContext<AppContextType>({
  withDoi: false,
  withImprint: true,
  withAgeConfirm: true,
  t: () => '',
  hasTranslation: () => false,
  switchLanguage: () => {},
  brand: 'Adecco',
  privacyConsentVersion: '',
  socialLinks: {
    Kununu: '',
    Xing: '',
    GoogleMaps: '',
    Facebook: '',
    LinkedIn: '',
  },
  withPrivacyPolicyModal: true,
  logoLink: '',
  withEmailConfirmation: false,
  withTracking: false,
  zipRegExp: '',
  varSource: '',
  withNoAts: false,
  withJsonSchema: false,
  mobileCVException: true,
});

export const useAppContext = () => {
  const appContext: AppContextType = useContext(AppContext);
  return { ...appContext };
};

export const AppProvider = ({
  children,
  withDoi,
  withImprint,
  withAgeConfirm,
  languages,
  brand,
  privacyConsentVersion,
  socialLinks,
  withPrivacyPolicyModal,
  logoLink,
  withEmailConfirmation,
  withTracking,
  zipRegExp,
  googleMapsApiKey,
  varSource,
  withNoAts,
  withJsonSchema,
  mobileCVException,
}: AppProviderProps) => {
  const router = useRouter();
  const [language, switchLanguage] = useState(router?.locale!.split('-')[0]);
  const t = (key: string): string => {
    // @ts-ignore
    return languages[language]?.[`${key}_${language}`]
      ? // @ts-ignore
        languages[language]?.[`${key}_${language}`]
      : // @ts-ignore
        languages[language]?.[key] ?? key;
  };
  // @ts-ignore
  const hasTranslation = (key: string): boolean => Boolean(languages[language]?.[key]);

  if (isJsonString(privacyConsentVersion)) {
    const privacyConsentVersionDict = JSON.parse(privacyConsentVersion);
    // eslint-disable-next-line no-param-reassign
    privacyConsentVersion =
      language === 'en'
        ? privacyConsentVersionDict.privacyConsentVersionEN?.trim()
        : privacyConsentVersionDict.privacyConsentVersionDE?.trim();
  }

  const value = useMemo(
    () => ({
      withDoi,
      withImprint,
      withAgeConfirm,
      brand,
      privacyConsentVersion,
      withPrivacyPolicyModal,
      socialLinks,
      logoLink,
      withEmailConfirmation,
      withTracking,
      zipRegExp,
      googleMapsApiKey,
      varSource,
      withNoAts,
      withJsonSchema,
      mobileCVException,
    }),
    [privacyConsentVersion]
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ ...value, t, switchLanguage, hasTranslation }}>
      {children}
    </AppContext.Provider>
  );
};
